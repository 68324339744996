<template>
  <div class="app-container">
    <div class="container common_inner">
      <div class="left">
        <el-card class="qu-content">
          <div class="qu-title">
            <div class="qu-name">
              {{ paperData.title }}
            </div>
            <exam-timer v-model="paperData.leftSeconds" @timeout="doHandler()" />
          </div>
          <div class="limit">
            <div>考试次数：{{ paperData.times || '无限' }}次</div>
            <div>达标要求：{{ paperData.qualifyScore }}分以上（总分{{ paperData.totalScore }}）</div>
          </div>
          <p v-if="quData.content" class="content-text">{{ quData.sort + 1 }}.{{ quData.content }}</p>
          <p v-if="quData.image != null && quData.image != ''">
            <el-image :src="quData.image" style="max-width: 100%" />
          </p>
          <div v-if="quData.quType === 1 || quData.quType === 3">
            <el-radio-group v-model="radioValue">
              <el-radio v-for="item in quData.answerList" :key="item.id" :label="item.id">
                {{ item.abc }}.{{ item.content }}
                <div v-if="item.image != null && item.image != ''" style="clear: both">
                  <el-image :src="item.image" style="max-width: 100%" />
                </div>
              </el-radio>
            </el-radio-group>
          </div>

          <div v-if="quData.quType === 2">
            <el-checkbox-group v-model="multiValue">
              <el-checkbox v-for="item in quData.answerList" :key="item.id" :label="item.id">
                {{ item.abc }}.{{ item.content }}
                <div v-if="item.image != null && item.image != ''" style="clear: both">
                  <el-image :src="item.image" style="max-width: 100%" />
                </div>
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <div v-if="quData.quType === 4">
            <el-input v-model="answer" />
          </div>
          <div class="footer" style="margin-top: 20px">
            <el-button :disabled="!showPrevious" type="primary" size="small" @click="handPrevious()">
              上一题
            </el-button>

            <el-button :disabled="!showNext" size="small" @click="handNext()"> 下一题 </el-button>
          </div>
        </el-card>
      </div>

      <div class="right" style="margin-bottom: 10px">
        <div>
          <el-button type="primary" style="width: 100%; margin-bottom: 10px" @click="examContent()">
            考试须知
          </el-button>

          <div class="card-item" v-if="paperData.radioList !== undefined && paperData.radioList.length > 0">
            <p class="card-title">
              <span class="text">单选题</span>
              <span class="count">共{{ paperData.radioList.length }}题</span>
            </p>
            <el-row :gutter="24" class="card-line">
              <el-tag
                :key="item.id"
                :class="['tag', cardItemClass(item.answered)]"
                v-for="item in paperData.radioList"
                @click="handSave(item)"
              >
                {{ item.sort + 1 }}</el-tag
              >
            </el-row>
          </div>

          <div class="card-item" v-if="paperData.multiList !== undefined && paperData.multiList.length > 0">
            <p class="card-title">
              <span class="text">多选题</span>
              <span class="count">共{{ paperData.multiList.length }}题</span>
            </p>
            <el-row :gutter="24" class="card-line">
              <el-tag
                :key="item.id"
                :class="['tag', cardItemClass(item.answered)]"
                v-for="item in paperData.multiList"
                @click="handSave(item)"
              >
                {{ item.sort + 1 }}</el-tag
              >
            </el-row>
          </div>

          <div class="card-item" v-if="paperData.judgeList !== undefined && paperData.judgeList.length > 0">
            <p class="card-title">
              <span class="text">判断题</span>
              <span class="count">共{{ paperData.judgeList.length }}题</span>
            </p>
            <el-row :gutter="24" class="card-line">
              <el-tag
                :key="item.id"
                :class="['tag', cardItemClass(item.answered)]"
                v-for="item in paperData.judgeList"
                @click="handSave(item)"
              >
                {{ item.sort + 1 }}</el-tag
              >
            </el-row>
          </div>

          <div class="card-item" v-if="paperData.inputList !== undefined && paperData.inputList.length > 0">
            <p class="card-title">
              <span class="text">填空题</span>
              <span class="count">共{{ paperData.inputList.length }}题</span>
            </p>
            <el-row :gutter="24" class="card-line">
              <el-tag
                :key="item.id"
                :class="['tag', cardItemClass(item.answered)]"
                v-for="item in paperData.inputList"
                @click="handSave(item)"
              >
                {{ item.sort + 1 }}</el-tag
              >
            </el-row>
          </div>
        </div>

        <el-button type="danger" style="width: 100%" :loading="loading" @click="handHandExam()">
          {{ handleText }}
        </el-button>
      </div>
    </div>

    <el-dialog title="本次考试成绩" :visible.sync="dialogShow" @close="handleClose">
      <div v-loading="loading" class="text-center">
        <el-progress
          style="margin-bottom: 30px"
          type="circle"
          :width="150"
          :percentage="paperDetail.percentage"
          :format="() => `${paperDetail.objScore}分`"
        ></el-progress>
        <div class="time" style="margin-bottom: 15px">开始时间：{{ paperDetail.createTime }}</div>
        <div class="time">结束时间：{{ paperDetail.updateTime }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { paperDetail, quDetail, handExam, fillAnswer } from '@/apis/studentsEndAPI/exam/index.js';
import { Loading } from 'element-ui';
import ExamTimer from './components/examTime.vue';

export default {
  name: 'ExamProcess',
  components: { ExamTimer },
  data() {
    return {
      // 全屏/不全屏
      dialogShow: false,
      isFullscreen: false,
      showPrevious: false,
      showNext: true,
      loading: false,

      paperDetail: {},
      handleText: '结束考试',
      pageLoading: false,
      // 试卷ID
      paperId: '',
      answer: '',
      // 当前答题卡
      cardItem: {},
      allItem: [],
      // 当前题目内容
      quData: {
        answerList: []
      },
      // 试卷信息
      paperData: {
        leftSeconds: 99999,
        radioList: [],
        multiList: [],
        judgeList: []
      },
      // 单选选定值
      radioValue: '',
      // 多选选定值
      multiValue: [],
      // 已答ID
      answeredIds: []
    };
  },
  created() {
    const id = this.$route.query.id;
    if (typeof id !== 'undefined' && id !== 'undefined') {
      this.paperId = id;
      this.fetchData(id);
    }
  },
  mounted() {
    window.oncontextmenu = () => false;
    document.onkeydown = () => {
      if (window.event && window.event.keyCode === 123) {
        return false;
      }
    };
    document.onselectstart = () => false;
  },

  beforeDestroy() {
    window.oncontextmenu = null;
    document.onkeydown = null;
    document.onselectstart = null;
    sessionStorage.removeItem('alertId');
  },

  methods: {
    // 答题卡样式
    cardItemClass: (answered) => (answered ? 'success' : 'info'),

    handleClose() {
      this.$router.push('/exam');
    },

    /**
     * 统计有多少题没答的
     * @returns {number}
     */
    countNotAnswered() {
      let notAnswered = 0;

      this.paperData.radioList.forEach(function (item) {
        if (!item.answered) {
          notAnswered += 1;
        }
      });

      this.paperData.multiList.forEach(function (item) {
        if (!item.answered) {
          notAnswered += 1;
        }
      });

      this.paperData.judgeList.forEach(function (item) {
        if (!item.answered) {
          notAnswered += 1;
        }
      });

      this.paperData.inputList.forEach(function (item) {
        if (!item.answered) {
          notAnswered += 1;
        }
      });

      return notAnswered;
    },

    /**
     * 下一题
     */
    handNext() {
      const index = this.cardItem.sort + 1;
      this.handSave(this.allItem[index]);
    },

    /**
     * 上一题
     */
    handPrevious() {
      const index = this.cardItem.sort - 1;
      this.handSave(this.allItem[index]);
    },

    doHandler() {
      this.handleText = '正在交卷，请等待...';
      this.loading = true;

      const params = { id: this.paperId };
      handExam(params).then(({ data }) => {
        this.$message({
          message: '试卷提交成功',
          type: 'success'
        });
        const { objScore = 0, totalScore = 0 } = data;
        this.paperDetail = {
          ...data,
          percentage: objScore / totalScore
        };
        this.dialogShow = true;
        this.loading = false;
      });
    },

    // 交卷操作
    async handHandExam() {
      await this.handSave(this.cardItem);
      const that = this;

      // 交卷保存答案
      const notAnswered = that.countNotAnswered();

      let msg = '确认要交卷吗？';

      if (notAnswered > 0) {
        msg = '您还有' + notAnswered + '题未作答，确认要交卷吗?';
      }

      that
        .$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          that.doHandler();
        })
        .catch(() => {
          that.$message({
            type: 'info',
            message: '交卷已取消，您可以继续作答！'
          });
        });
    },

    // 保存答案
    handSave(item) {
      return new Promise((resolve) => {
        if (item.id === this.allItem[0].id) {
          this.showPrevious = false;
        } else {
          this.showPrevious = true;
        }

        // 最后一个索引
        const last = this.allItem.length - 1;

        if (item.id === this.allItem[last].id) {
          this.showNext = false;
        } else {
          this.showNext = true;
        }

        const answers = this.multiValue;
        if (this.radioValue !== '') {
          answers.push(this.radioValue);
        }

        const params = { paperId: this.paperId, quId: this.cardItem.quId, answers: answers, answer: '' };

        if (this.cardItem.quType === 4) {
          answers.push(this.answer.trim());
          params.answer = this.answer.trim();
        }

        this.answer = '';

        fillAnswer(params).then(() => {
          // 必须选择一个值
          if (answers.length > 0) {
            // 加入已答列表
            this.cardItem.answered = true;

            // 填空题单独处理
            if (this.cardItem.quType === 4) {
              const [answer] = answers;
              this.cardItem.answered = Boolean(answer);
            }
          }
          resolve();

          // 查找详情
          this.fetchQuData(item);
        });
      });
    },

    examContent() {
      this.$alert(this.paperData.content);
    },

    // 试卷详情
    fetchQuData(item) {
      // 打开
      const loading = Loading.service({
        text: '拼命加载中',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      // 获得详情
      this.cardItem = item;

      // 查找下个详情
      const params = { paperId: this.paperId, quId: item.quId };
      quDetail(params).then((response) => {
        this.quData = response.data;
        this.radioValue = '';
        this.multiValue = [];

        // 填充该题目的答案
        this.quData.answerList.forEach((item) => {
          if ((this.quData.quType === 1 || this.quData.quType === 3) && item.checked) {
            this.radioValue = item.id;
          }

          if (this.quData.quType === 2 && item.checked) {
            this.multiValue.push(item.id);
          }
        });

        if (this.quData.quType === 4) {
          this.answer = this.quData.answer;
        }

        // 关闭详情
        loading.close();
      });
    },

    // 试卷详情
    fetchData(id) {
      const params = { id: id };
      paperDetail(params).then((response) => {
        // 试卷内容
        this.paperData = response.data;

        if (!sessionStorage.getItem('alertId')) {
          this.examContent();
        }

        sessionStorage.setItem('alertId', response.data.id);



        // 获得第一题内容
        if (this.paperData.radioList && this.paperData.radioList.length) {
          this.cardItem = this.paperData.radioList[0];
        } else if (this.paperData.multiList && this.paperData.multiList.length) {
          this.cardItem = this.paperData.multiList[0];
        } else if (this.paperData.judgeList && this.paperData.judgeList.length) {
          this.cardItem = this.paperData.judgeList[0];
        } else if (this.paperData.inputList && this.paperData.inputList.length) {
          this.cardItem = this.paperData.inputList[0];
        }

        const that = this;

        this.paperData.radioList.forEach(function (item) {
          that.allItem.push(item);
        });

        this.paperData.multiList.forEach(function (item) {
          that.allItem.push(item);
        });

        this.paperData.judgeList.forEach(function (item) {
          that.allItem.push(item);
        });

        this.paperData.inputList.forEach(function (item) {
          that.allItem.push(item);
        });

        const last = this.allItem.length - 1;
        this.showNext = this.cardItem?.id !== this.allItem[last]?.id;

        // 当前选定
        this.fetchQuData(this.cardItem);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.footer {
	text-align: center;
}
.qu-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	.qu-name {
		height: 25px;
		color: #0a1629;
		font-weight: bold;
		font-size: 18px;
		font-family: PingFang-SC-Bold, PingFang-SC;
		line-height: 25px;
	}
}
.tag {
	margin: 1px;
	width: 40px;
	height: 40px;
	border: 1px solid #ececec;
	background: #fff;
	color: #666;
	text-align: center;
	line-height: 40px;
	&.success {
		border: 1px solid #8fb8f5;
		background: #d4e5ff;
	}
}
.limit {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	color: #91929e;
	font-weight: 400;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	line-height: 20px;
}
.card-item {
	margin-bottom: 10px;
	padding: 20px;
	border-radius: 10px;
	background: #fff;
}
.qu-content {
	padding: 25px;
	border-radius: 10px;
}
.qu-content div {
	line-height: 30px;
	.content-text {
		margin-bottom: 12px;
		color: #0a1629;
		font-weight: 400;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		line-height: 24px;
	}
}

.el-checkbox-group label,
.el-radio-group label {
	width: 100%;
}

.card-title {
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	.text {
		color: #0a1629;
		font-weight: bold;
		font-size: 16px;
		font-family: PingFang-SC-Bold, PingFang-SC;
	}
	.count {
		color: #0a1629;
		font-weight: 400;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
	}
}
.card-line {
	margin: 10px 0 0 !important;
	font-size: 0;
}
.card-line span {
	cursor: pointer;
}

::v-deep {
	.el-radio,
	.el-checkbox {
		margin-bottom: 10px;
		padding: 9px 20px 9px 10px;
		border: 1px solid #dcdfe6;
		border-radius: 4px;
	}

	.is-checked {
		border: #409eff 1px solid;
	}

	.el-radio img,
	.el-checkbox img {
		max-width: 200px;
		max-height: 200px;
		border: #dcdfe6 1px dotted;
	}
}

::v-deep {
	.el-checkbox__inner {
		display: none;
	}
}

::v-deep {
	.el-radio__inner {
		display: none;
	}
}

::v-deep {
	.el-checkbox__label {
		line-height: 30px;
	}
}

::v-deep {
	.el-radio__label {
		line-height: 30px;
	}
}
.app-container {
	overflow-x: hidden;
	padding-top: 30px;
	background: #f7faff;
}
.container {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	.left {
		flex: 1;
		margin-right: 25px;
	}
	.right {
		flex: 0 0 292px;
	}
}
.text-center {
	text-align: center;
}
.time {
	color: #91929e;
	font-weight: 400;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	line-height: 20px;
}
</style>

