<template>

  <div>
    <span>倒计时：</span>
    <div class="num">{{ min }}</div><span>分</span>
    <div class="num">{{ sec }}</div><span>秒</span>
  </div>

</template>

<script>

export default {
  name: 'ExamTimer',
  props: {
    value: Number
  },

  data() {
    return {
      leftSeconds: 0,
      min: '00',
      sec: '00'
    }
  },

  watch: {
    value: {
      handler() {
        this.leftSeconds = this.value
        this.countdown()
      }
    }
  },

  created() {
    this.leftSeconds = this.value
  },

  methods: {

    // 进行倒计时
    countdown() {
      // 倒计时结束了
      if (this.leftSeconds < 0) {
        this.$emit('timeout')
        return
      }

      // 时
      const min = parseInt(this.leftSeconds / 60)
      const sec = parseInt(this.leftSeconds % 60)

      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec
      this.leftSeconds -= 1

      const that = this
      setTimeout(function () {
        that.countdown()
      }, 1000)
    }

  }
}
</script>
<style lang="scss" scoped>
.num {
	display: inline-block;
	margin: 0 8px;
	padding: 0 8px;
	height: 38px;
	border-radius: 2px;
	background: #401e1e;
	color: #fff;
	font-size: 18px;
	font-family: DINAlternate-Bold, DINAlternate;
	line-height: 38px;
}
</style>

