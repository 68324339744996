<template>
  <div class="app-container">
    <div class="container common_inner">
      <div class="left">
        <header>
          <h2>
            {{ paperData.title }}
          </h2>
          <div class="score">
            <span>得分：</span>
            <span class="num">{{ paperData.userScore }}</span>
          </div>
        </header>

        <div class="flex-between" style="margin-top: 10px">
          <div>考生姓名：{{ paperData.userId_dictText }}</div>
          <div style="text-align: right">考试用时：{{ paperData.userTime }}分钟</div>
        </div>

        <div class="qu-wrapper" style="margin-top: 20px">
          <div v-for="item in paperData.quList" :ref="item.id" :key="item.id" class="qu-content">
            <p class="qu-title">{{ item.sort + 1 }}. &emsp;{{ item.content }}（分数：{{ item.actualScore }}）</p>
            <p v-if="item.image != null && item.image != ''">
              <el-image :src="item.image" style="max-width: 100%" />
            </p>
            <div v-if="item.quType === 1 || item.quType === 3">
              <el-radio-group v-model="radioValues[item.id]">
                <el-radio v-for="an in item.answerList" :key="an.id" :label="an.id">
                  {{ an.abc }}.{{ an.content }}
                  <div v-if="an.image != null && an.image != ''" style="clear: both">
                    <el-image :src="an.image" style="max-width: 100%" />
                  </div>
                </el-radio>
              </el-radio-group>

              <div class="qu-item">
                <div style="color: #24da70">正确答案：{{ radioRights[item.id] }}</div>

                <div v-if="!item.answered" style="text-align: right; color: #ff0000">答题结果：未答</div>

                <div v-if="item.answered && !item.isRight" style="text-align: right; color: #ff0000">
                  答题结果：{{ myRadio[item.id] }}
                </div>

                <div v-if="item.answered && item.isRight" style="text-align: right; color: #24da70">
                  答题结果：{{ myRadio[item.id] }}
                </div>
              </div>
            </div>

            <div v-if="item.quType === 4">
              <div>
                <div class="flex-between">
                  <span>我的回答：</span>
                  <div>
                    <el-input disabled style="display: inline" :value="item.answer" />
                  </div>
                </div>
                <div class="flex-between">
                  <span>正确答案：</span>
                  <div>
                    {{ item.answerList[0].content }}
                  </div>
                </div>
              </div>
            </div>

            <div v-if="item.quType === 2">
              <el-checkbox-group v-model="multiValues[item.id]">
                <el-checkbox v-for="an in item.answerList" :key="an.id" :label="an.id">
                  {{ an.abc }}.{{ an.content }}
                  <div v-if="an.image != null && an.image != ''" style="clear: both">
                    <el-image :src="an.image" style="max-width: 100%" />
                  </div>
                </el-checkbox>
              </el-checkbox-group>

              <el-row :gutter="24">
                <el-col :span="12" style="color: #24da70"> 正确答案：{{ multiRights[item.id].join(',') }} </el-col>

                <el-col v-if="!item.answered" :span="12" style="text-align: right; color: #ff0000">
                  答题结果：未答
                </el-col>

                <el-col v-if="item.answered && !item.isRight" :span="12" style="text-align: right; color: #ff0000">
                  答题结果：{{ myMulti[item.id].join(',') }}
                </el-col>

                <el-col v-if="item.answered && item.isRight" :span="12" style="text-align: right; color: #24da70">
                  答题结果：{{ myMulti[item.id].join(',') }}
                </el-col>
              </el-row>
            </div>
            <!-- <div class="anallysis">解析：这个是题目杰西娅</div> -->
            <div class="analysis" v-if="item.analysis">解析：{{ item.analysis }}</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="card-line" ref="qu-card">
          <el-tag
            :class="['tag', cardItemClass(item.right)]"
            :key="item.id"
            v-for="item in paperData.quList"
            @click="scrollTo(item)"
          >
            {{ item.sort + 1 }}</el-tag
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { paperResult } from '@/apis/studentsEndAPI/exam/index.js';

export default {
  data() {
    return {
      // 试卷ID
      paperId: '',
      paperData: {
        quList: []
      },
      radioValues: {},
      multiValues: {},
      radioRights: {},
      multiRights: {},
      myRadio: {},
      myMulti: {}
    };
  },
  created() {
    const id = this.$route.params.id;
    if (typeof id !== 'undefined') {
      this.paperId = id;
      this.fetchData(id);
    }
  },
  methods: {
    cardItemClass: (right) => (right ? 'success' : 'info'),

    scrollTo(item) {
      const dom = this.$refs[item.id.toString()][0];
      dom && dom.scrollIntoView();
      // this.$refs['qu-card'].scrollIntoView()
    },

    fetchData(id) {
      const params = { id: id };
      paperResult(params).then((response) => {
        // 试卷内容
        this.paperData = response.data;
        this.paperData.quList // 填充该题目的答案
          .forEach((item) => {
            let radioValue = '';
            let radioRight = '';
            let myRadio = '';
            const multiValue = [];
            const multiRight = [];
            const myMulti = [];

            item.answerList.forEach((an) => {
              // 用户选定的
              if (an.checked) {
                if (item.quType === 1 || item.quType === 3) {
                  radioValue = an.id;
                  myRadio = an.abc;
                } else {
                  multiValue.push(an.id);
                  myMulti.push(an.abc);
                }
              }

              // 正确答案
              if (an.right) {
                if (item.quType === 1 || item.quType === 3) {
                  radioRight = an.abc;
                } else {
                  multiRight.push(an.abc);
                }
              }
            });

            this.multiValues[item.id] = multiValue;
            this.radioValues[item.id] = radioValue;

            this.radioRights[item.id] = radioRight;
            this.multiRights[item.id] = multiRight;

            this.myRadio[item.id] = myRadio;
            this.myMulti[item.id] = myMulti;
          });

      });
    }
  }
};
</script>

<style scoped lang="scss">
.qu-content {
	padding-bottom: 20px;
}

.qu-content div {
	line-height: 30px;
}

.el-checkbox-group label,
.el-radio-group label {
	width: 100%;
}

.card-title {
	background: #eee;
	text-align: center;
	font-size: 14px;
	line-height: 35px;
}
.card-line span {
	margin: 2px;
	cursor: pointer;
}
.flex-between {
	display: flex;
	align-items: center;
	justify-content: space-between;
	span {
		flex: 0 0 80px;
	}
	div {
		flex: 1;
	}
}

.qu-title {
	margin-bottom: 16px;
}

.app-container {
	overflow-x: hidden;
	padding-top: 30px;
	background: #f7faff;
}
.container {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;

	.left {
		flex: 1;
		margin-right: 25px;
		padding: 25px;
		border-radius: 10px;
		background: #fff;
		header {
			display: flex;
			align-items: center;
			align-items: center;
			justify-content: space-between;
			h2 {
				color: #0a1629;
				font-weight: bold;
				font-size: 18px;
				font-family: PingFang-SC-Bold, PingFang-SC;
			}
			.score {
				span {
					overflow: hidden;
					height: 32px;
					line-height: 32px;
				}
				.num {
					color: #d37474;
					font-weight: bold;
					font-size: 32px;
					font-family: DINAlternate-Bold, DINAlternate;
				}
			}
		}
	}
	.right {
		flex: 0 0 292px;
		padding-top: 20px;
	}
}
::v-deep {
	.el-row {
		margin: 0;
	}
}

.qu-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.card-line {
	position: sticky;
	top: 0;
	margin: 10px 0 0 !important;
	padding-left: 15px;
	font-size: 0;
}
.card-line span {
	cursor: pointer;
}

.right {
	position: relative;
	border-radius: 10px;
	background: #fff;
}

.tag {
	margin: 1px;
	width: 40px;
	height: 40px;
	border: 1px solid #ececec;
	background: #fff;
	color: #666;
	text-align: center;
	line-height: 40px;
	&.success {
		border: 1px solid #8fb8f5;
		background: #d4e5ff;
	}
	&.info {
		border: 1px solid #fab6b6;
		background: #ffebeb;
	}
}
</style>

