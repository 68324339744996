<template>
  <div class="exam">
    <div class="exam-select-wrapper">
      <div class="contianer common_inner">
        <category
          :searchType="1"
          @subjectId="subjectId"
          @secondList="secondList"
          :subjectInd="subjectInd"
          :subjectChilInd="subjectChilInd"
          :chilrenList="chilrenList"
        />
        <div class="exam-operate">
          <span class="select-name">考试状态：</span>

          <ul class="exam-operate-list">
            <li
              v-for="item in EXAM_STATUS"
              @click="setState(item)"
              :class="{ active: currentState.val === item.val, mr8: true }"
              :key="item.val"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
        <div class="sort-wrapper">
          <ul class="sort-list">
            <li @click="setSort(1)">
              <span :class="{ active: [1, 2].includes(sortBy) }">最新发布</span>
              <i :class="['el-icon-top', ...(sortBy === 1 ? 'active' : '')]"/>
              <i :class="['el-icon-bottom', ...(sortBy === 2 ? 'active' : '')]"/>
            </li>
            <li @click="setSort(3)">
              <span :class="{ active: [3, 4].includes(sortBy) }">最多参与</span>
              <i :class="['el-icon-top', ...(sortBy === 3 ? 'active' : '')]"/>
              <i :class="['el-icon-bottom', ...(sortBy === 4 ? 'active' : '')]"/>
            </li>
          </ul>
        </div>

        <div class="exam-wrapper">
          <div class="exam-list">
            <div class="exam-item" :key="item.id" v-for="item in examList">
              <div class="exam-header">
                <span class="time">
                  <span>考试时间：</span>
                  <span>{{ item.timeLimit ? `${item.startTime} ~ ${item.endTime}` : '不限时' }}</span>
                </span>
                <span :class="['state', stateClass.get(item.state)]">{{ examMap.get(item.state) }}</span>
              </div>
              <div class="exam-content">
                <div class="exam-title">
                  {{ item.title }}
                </div>
                <div class="exam-info">
                  <div class="exam-creator">{{ item.userName }}</div>
                  <div class="crete-time">创建于：{{ item.createTime }}</div>
                  <div class="exam-examtor">{{ item.peopleCount }}人已参与</div>
                </div>
                <div class="exam-item-operate">
                  <div class="quesiton-count">共{{ item.quCount }}题</div>
                  <div class="limit-time">限时{{ item.totalTime }}分钟 &emsp;满分{{ item.totalScore }}分</div>
                  <el-button size="small" :disabled="item.state !== 2" @click="startExam(item)">开始考试</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center">
            <pagination
              :page-size="30"
              background
              layout="prev, pager, next,jumper"
              :total="totalCount"
              @pageChange="currentChange"
            ></pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="contianer common_inner">

    </div> -->
  </div>
</template>

<script>
import {EXAM_STATUS, LockMap} from '@/utils/enum.js'
import {createExam, fetchExamList} from '@/apis/studentsEndAPI/exam/index.js'
import {fetchMeaicalList} from '@/apis/backStageEndAPI/examStageManagement/index.js'
import {tree2arr} from '@/utils/index.js'
import pagination from '@/components/backStageComponent/pagination'
import category from '../../../studentsEnd/live/components/category'
import {EventBus} from "@assets/js/eventBus";
export default {
  name: 'Exam',
  components: {pagination, category},
  data() {
    return {
      examMap: EXAM_STATUS,
      currentSubject: {medicalName: '全部', medicalId: ''},
      currentState: {label: '全部', val: ''},
      totalCount: 100,
      currentPage: 1,
      sortBy: 1,
      examList: [{}],
      medicalList: [],
      subjectInd: '',
      subjectChilInd: '',
      chilrenList: {},
      typeId: '',
      stateClass: new LockMap([
        [1, 'soon'],
        [2, 'ing'],
        [3, 'ed'],
        [4, 'ed'],
      ]),
    }
  },
  watch: {
    '$store.state.student.value': {
      handler(val) {
        if (val == '') {
          this.getList()
        }
      },
      deep: true,
    },
  },
  mounted() {
    EventBus.$emit("curIndex", 5);
  },
  created() {
    if (sessionStorage.getItem("examList")) {
      let data = JSON.parse(sessionStorage.getItem("examList"));
      if (data.records.length > 0) {
        this.totalCount = data.total
        this.examList = data.records
      }
    } else if (sessionStorage.getItem("nodata")) {
      this.dibbledata = [];
    } else {
      this.getList();
    }
    this.getMedicalList()
  },
  beforeDestroy() {
    sessionStorage.removeItem("examList");
    sessionStorage.removeItem("nodata");
  },
  methods: {
    subjectId(subjectId) {
      this.typeId = subjectId
      this.getList()
    },
    secondList(args) {
      const [current] = args
      this.typeId = current?.medicalId
      this.getList()
    },
    setSubject(subject) {
      this.currentSubject = subject
      this.getList()
    },
    setState(state) {
      this.currentState = state
      this.getList()
    },
    setSort(state) {
      if (state === 1) {
        this.sortBy = this.sortBy === 1 ? 2 : 1
      } else {
        this.sortBy = this.sortBy === 3 ? 4 : 3
      }
      this.getList()
    },
    currentChange(currentPage) {
      this.currentPage = currentPage
      this.getList()
    },
    async getMedicalList() {
      const list = await fetchMeaicalList()
      this.medicalList = tree2arr(list)
      this.medicalList.unshift({medicalName: '全部', medicalId: ''})
    },
    async startExam(exam) {
      if (sessionStorage.getItem('educationToken') || localStorage.getItem('educationToken')) {
        const {times, tryCount = 0} = exam
        if (times) {
          if (times - tryCount < 0 || !(times - tryCount)) {
            return this.$message.warning('您没有剩余的考试次数了')
          }
        }
        const {data} = await createExam({
          examId: exam.id,
          password: '',
        })
        this.$router.push(`/answer?id=${data.id}`)
      } else {
        this.$message.warning('请先登录！')
        this.$store.state.isLogin = true
      }
    },
    async getList() {
      const {data} = await fetchExamList({
        current: this.currentPage,
        size: 30,
        orderBy: this.sortBy,
        params: {
          state: this.currentState.val,
          typeId: this.typeId,
          title: this.$store.state.student.value
        },
      })
      this.totalCount = data?.total || 0
      this.examList = data?.records || []
    },
  },
  computed: {
    EXAM_STATUS() {
      const arr = Array.from(EXAM_STATUS)
        .slice(0, 3)
        .map(([val, label]) => {
          return {
            label,
            val,
          }
        })
      arr.unshift({label: '全部', val: ''})
      return arr
    },
  },
}
</script>

<style lang="scss" scoped>
.exam {
  padding-bottom: 30px;
  height: calc(100% - 30px);
  background: #f7faff;
  .contianer {
    overflow-x: hidden;
  }
  &- {
    &select-wrapper {
      background-color: #fff;
      height: 100%;
    }
    &operate {
      display: flex;
      justify-content: space-between;
      padding: 14px 0;
      &:first-child {
        border-bottom: 1px solid #e6e6e6;
      }
      .select-name {
        flex: 0 0 70px;
        padding: 7px;
        color: #333;
      }
      &-list {
        flex: 1;
        li {
          display: inline-block;
          padding: 7px 10px;
          color: #333;
          cursor: pointer;
          &.active {
            border-radius: 5px;
            background: #d4e5ff;
            color: var(--colorBlue);
          }
        }
      }
    }
    &item {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 20px;
      width: 384px;
      height: 159px;
      border: 1px solid #e6e6e6;
      border-radius: 3px;
      background: #fff;
      .state {
        padding: 0 8px;
        height: 24px;
        border-radius: 100px 0 0 100px;
        color: #fff;
        font-family: PingFangSC-Medium, PingFang SC;
        line-height: 24px;
        &.ing {
          background: var(--lightGreen);
        }
        &.ed {
          background: #ced5e0;
        }
        &.soon {
          background: var(--colorOrange);
        }
      }
    }
    &header {
      display: flex;
      align-items: center;
      flex: 0 0 40px;
      justify-content: space-between;
      padding: 8px 0 8px 12px;
      background: #f7f7f7;
      color: #333;
      font-size: 12px;
    }
    &list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &:after {
        visibility: hidden;
        width: 384px;
        height: 0;
        content: "";
      }
    }
    &content {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      padding: 12px;
    }
    &title {
      height: 20px;
      color: #333;
      font-weight: 500;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 20px;
    }
    &info {
      display: flex;
      justify-content: space-around;
      height: 20px;
      color: #91929e;
      font-weight: 400;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      line-height: 20px;
    }
    &item-operate {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.sort-list {
  padding: 14px 0;
  li {
    display: inline-block;
    margin-right: 12px;
    padding: 0 12px;
    height: 25px;
    border-radius: 13px;
    color: #333;
    font-weight: 400;
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    line-height: 25px;
    cursor: pointer;
    &.active {
      background: #d4e5ff;
      color: var(--colorBlue);
      cursor: pointer;
    }
    .active {
      color: var(--colorBlue);
    }
  }
}
.butn {
  width: 90px;
  height: 30px;
  border-radius: 5px;
  background: var(--colorBlue);
  color: #fff;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
</style>
